import Vue from 'vue'

import Router from 'vue-router'

import MonthReport from '../views/monthReport/index.vue'
Vue.use(Router)
export default new Router({
    routes:[
        {
            path: '/',
            component: MonthReport
        }
        ],
    mode: "history"
})