<template>
  <div class="app-container">

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>查询条件</span>
      </div>
      <el-form :model="queryParams" ref="queryForm" size="small" :inline="true"  label-width="100px">
        <el-form-item label="部门名称" prop="departmentName">
          <el-input v-model="queryParams.departmentName" placeholder=""  />
        </el-form-item>

        <el-form-item label="日期范围" prop="dateRange">
          <el-date-picker
              v-model="date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="box-card mt20">
      <div slot="header" class="clearfix text-center">
        <h2>{{companyName}}公司2024年1-{{monthData}}月业务运行报告</h2>
      </div>
      <div class="content">
        <h4>一.销售情况</h4>
        <span>（一）整体情况</span>
        <div class="contentMain">2024 年<label> {{monthData}} </label> 月新增销售合同 <label> {{test}} </label> 份，合计 <label> {{test}} </label> 万元。</div>
        <div class="contentMain">2024 年 1 -<label> {{monthData}} </label>月累计实现销售合同<label> {{test}} </label>万元，同比增长<label> {{test}} </label>%。</div>
        <div class="contentMain">本月新签销售合同完成情况如下：</div>
        <div class="contentMain">

        </div>
      </div>

    </el-card>



  </div>
</template>

<script>
export default {
  inject: ['reload'],
  name: "index.vue",
  data() {
    return {
      // 日期默认值
      date:'',
      // 公司名称数据
      companyName:'上海机电有限',
      // 公司月份
      monthData: 12,
      //临时数据
      test:12,
      // 查询参数
      queryParams: {},
    }
  },
  mounted() {
    // 监听resize方法
    window.addEventListener("resize", this.renderResize, false);
  },
  methods:{
    handleQuery(){},
    resetQuery(){},
    renderResize() {
      // 判断横竖屏
      let width = document.documentElement.clientWidth;
      let height = document.documentElement.clientHeight;
      // alert(width+"宽高"+height)
      if (width > height) {
        // alert('横屏')
        window.location.reload();
        console.log('页面横屏刷新了')
      } else {
        // alert('竖屏')
        window.location.reload();
        console.log('页面竖屏刷新了')
      }
      // 做页面适配
      // 注意：renderResize方法执行时虚拟dom尚未渲染挂载，如果要操作vue实例，最好在this.$nextTick()里进行。
    }
  }
}
</script>

<style scoped>
  .mt20{
    margin-top: 10px;
  }
  .text-center{
    text-align: center;
  }
  .content{
    padding: -10px 40px !important;
  }
  .contentMain{
    padding: 15px 10px 0px 10px;
  }
  .contentMain label{
    font-weight: bold;
    color: #333;
  }
</style>